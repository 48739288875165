<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table style="width: 100%">
                        <tr>
                            <td class="td-text">
                                协议编号：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入试用协议编号"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                公司名称：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                销售人员：
                            </td>
                            <td class="td-input">
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px">
                                联系电话：
                            </td>
                            <td class="td-input" style=" padding-top: 10px">
                                <el-input v-model="searchItem.userTel" size="small" placeholder="请输入联系电话"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                公司类型：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <template>
                                    <el-select style="width: 100%;" v-model="searchItem.companyType" size="small"
                                               class="handle-input"
                                               placeholder="请选择公司类型">
                                        <el-option
                                                v-for="item in unitTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                审批状态：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <template>
                                    <el-select style="width: 100%;" v-model="searchItem.auditState" size="small"
                                               class="handle-input"
                                               placeholder="请选择审批状态">
                                        <el-option
                                                v-for="item in approvalStatuses"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px">
                                生成时间：
                            </td>
                            <td class="td-input" style="padding-top: 10px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width:47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width:46%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td style="text-align: right;padding-top: 10px">项目名称：</td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.projectName" size="small" placeholder="请输入项目名称"
                                ></el-input>
                            </td>
                            <td colspan="2" style="padding-top: 10px;text-align: center">
                                <!--
                                <el-button type="primary" icon="el-icon-circle-plus" size="small" @click="addContract">增加</el-button>
                                -->

                                <!--<el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button>
                                -->
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh-right" size="small"
                                           style="margin-left: 25%" @click="resetBtn">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            width
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="协议编号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="userName" label="销售人员" width="110" align="center"></el-table-column>
                            <el-table-column prop="userTel" label="联系电话" width="110" align="center"></el-table-column>
                            <el-table-column label="测试周期" width="140" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{scope.row.startDate}} ~ {{scope.row.endDate}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="companyName" label="公司名称" width="140" show-overflow-tooltip
                                             align="left"></el-table-column>
                            <el-table-column prop="unitType" label="公司类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else-if="scope.row.companyType == 4">集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目名称" width="140" show-overflow-tooltip
                                             align="left">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.projectName == ''">无</span>
                                    <span v-else>{{scope.row.projectName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="approvalStatus" label="审批状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.auditState == 0">草稿</span>
                                    <span v-else-if="scope.row.auditState == -1" style="color: red">已驳回</span>
                                    <span v-else-if="scope.row.auditState == 10" style="color: green">已通过</span>
                                    <span v-else-if="scope.row.auditState == 5" style="color: #F0AD4E">待发货</span>
                                    <span v-else style="color: blue">审批中</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="生成时间" :formatter="dateFormat" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="uploadFile" label="上传附件" width="90" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.auditState == 10" type="text"
                                               @click="uploadFiles(scope.row.id)">上传附件
                                    </el-button>
                                    <el-button v-if="scope.row.auditState != 10" type="text" disabled>上传附件</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column label="发货单" width="70" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button v-if="scope.row.auditState == 10" type="text" @click="shippingShow(scope.row.id)">发货单
                                    </el-button>
                                    <el-button v-if="scope.row.auditState != 10" type="text" disabled>发货单</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" label="操作" width="200" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑试用"
                                           v-if="scope.row.auditState == 0 && hasAuthority('test_protocol_update')
                                           || scope.row.auditState == -1 && hasAuthority('test_protocol_update')"
                                           @click="editTest(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing"
                                           title="查看详情"
                                           @click="showTest(scope.row.id)"></i>

                                        <i class="iconfont icontongguo" title="提交申请"
                                           v-if="scope.row.auditState == 0 && hasAuthority('test_protocol_apply_audit')
                                           || scope.row.auditState == -1 && hasAuthority('test_protocol_apply_audit')"
                                           @click="submitTest(scope.row.id)"></i>
                                        <i class="iconfont iconzengjia" title="转为正式合同"
                                           style="margin-left: 20px"
                                           v-if="scope.row.auditState == 10 && hasAuthority('test_protocol_convert')"
                                           @click="toChangeFormal(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="data-dialog">
            <el-dialog
                    :title="editTile"
                    :visible.sync="dialogVisible"
                    :close-on-click-modal="isClose"
                    width="70%"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body">
                    <div class="basic-data">
                        <div class="text-title">
                            基础数据
                        </div>
                        <el-form :model="testForm" :rules="rules" ref="testForm" label-width="100px"
                                 class="demo-ruleForm">
                            <el-form-item size="small" label="协议名称:" prop="contractName">
                                <el-input size="small" v-model.trim="testForm.contractName"></el-input>
                            </el-form-item>
                            <el-row>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="协议号:" prop="contractNum">
                                        <el-input size="small" v-model="testForm.contractNum"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="公司名称:" prop="companyName">
                                        <el-input size="small" disabled v-model="testForm.companyName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="公司类型:" prop="companyType">
                                        <el-select disabled v-model="testForm.companyType" style="width: 100%;"
                                                   placeholder="请选择公司类型">
                                            <el-option
                                                    v-for="item in unitTypes"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="设备类型:" prop="isTaxpayer"
                                                  style="text-align: center">
                                        <span v-if="testForm.deviceType == 0">普通版</span>
                                        <span v-else>加强版</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="塔机数量:" prop="towerCount">
                                        <el-input size="small" disabled v-model.number="testForm.towerCount"
                                                  autocomplete="off"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="升降机数量:" prop="lifterCount">
                                        <el-input size="small" disabled v-model.number="testForm.lifterCount"
                                                  autocomplete="off"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="开始日期:" prop="startDate">
                                        <el-date-picker
                                                v-model="testForm.startDate"
                                                type="date"
                                                style="width: 100%"
                                                placeholder="选择日期时间"
                                                value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" style="width: 100%" label="截止日期:" prop="endDate">
                                        <el-date-picker
                                                v-model="testForm.endDate"
                                                type="date"
                                                style="width: 100%"
                                                placeholder="选择日期时间"
                                                value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="是否安装:" prop="isInstalled"
                                                  style="text-align: center">
                                        <span v-if="testForm.isInstall == 1">是</span>
                                        <span v-else>否</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10" style="width: 50%">
                                    <el-form-item size="small" label="项目名称:" prop="projectName"
                                                  style="text-align: center">
                                        <span>{{testForm.projectName}}</span>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item size="small" label="备注:" prop="chargePolicy">
                                <el-input type="textarea" size="small" :rows="3"
                                          v-model="testForm.remark"></el-input>
                            </el-form-item>
                        </el-form>
                        <table style="width: 100%">
                            <tr>
                                <td style="width: 92px">功能需求：</td>
                                <td colspan="5">
                                    <div class="td-body">
                                        <table cellpadding="0" cellspacing="0" style="width: 100%">
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                    序号
                                                </td>
                                                <td :key="i" v-for="(item,i) in towerItems"
                                                    style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    {{item.index}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                    塔机
                                                </td>
                                                <td :key="i" v-for="(item,i) in towerItems"
                                                    style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    {{item.text}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    数量
                                                </td>
                                                <td :key="i" v-for="(item,i) in towerItems"
                                                    style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                    {{item.quality}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                    升降机
                                                </td>
                                                <td :key="i" v-for="(item,i) in lifterItems"
                                                    style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    {{item.text}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    数量
                                                </td>
                                                <td :key="i" v-for="(item,i) in lifterItems"
                                                    style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                    {{item.quality}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                    特殊型号
                                                </td>
                                                <td :key="i" v-for="(item,i) in specialItems"
                                                    style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    {{item.text}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                    数量
                                                </td>
                                                <td :key="i" v-for="(item,i) in specialItems"
                                                    style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                    {{item.quality}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <template>
                            <el-table
                                    :data="basicTable"
                                    style="width: 100%"
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: '41px'}">
                                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                                <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                        <span v-else>塔机</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="sizeModel" label="规格型号"></el-table-column>
                                <el-table-column prop="siteNum" label="现场编码"></el-table-column>
                                <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"></el-table-column>
                                <el-table-column prop="armLength" label="前臂长度(m)"></el-table-column>
								<el-table-column prop="rearArmLength" label="后臂长度(m)"></el-table-column>
                                <el-table-column prop="maxLoad" label="最大载重(T)"></el-table-column>
                                <el-table-column prop="deviceType" label="类型"></el-table-column>
                                <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isNeedCard == 1">是</span>
                                        <span v-else>否</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isNeedModel == 1">是</span>
                                        <span v-else>否</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                    <div class="approval-table">
                        <div class="text-title">
                            审批历史
                        </div>
                        <template>
                            <el-table
                                    :data="approvalRecord"
                                    border
                                    style="width: 100%">
                                <el-table-column prop="depName" label="部门"></el-table-column>
                                <el-table-column prop="userName" label="审批人"></el-table-column>
                                <el-table-column prop="auditRemark" label="意见">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.auditRemark == null">无</span>
                                        <span v-else>{{scope.row.auditRemark}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="时间" :formatter="dateFormat">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.createTime == null">无</span>
                                        <span v-else>{{scope.row.createTime}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isPass == 0 && scope.row.isEffective == 1"
                                              style="color: red">驳回</span>
                                        <span v-else-if="scope.row.isPass == 0 && scope.row.isEffective == 0"
                                              style="color: #F08080">驳回</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 1"
                                              style="color: green">通过</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 0"
                                              style="color:#98FB98">通过</span>
                                        <span v-else style="color: blue">未审批</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" :loading="isDisabled" @click="saveTest('testForm')">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="upload-dialog">
            <Upload @openDialog="openDialog" :fileId="contractId" :fileType="uploadType"
                    :dialogVisibles="dialogVisibles"/>
        </div>
        <div class="shipping-dialog">
            <el-dialog
                    title="发货单据详情"
                    :visible.sync="shippingVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-table
                        :data="shippingData"
                        style="width: 100%;height: 100%;" :border="true" @selection-change="chooseSelection">
                    <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                    <el-table-column prop="outListNum" label="发货单据" width="150" align="center"></el-table-column>
                    <el-table-column prop="createTime" label="发货时间" width="120" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sendUser" label="操作人员" width="140" align="center"></el-table-column>
                    <el-table-column prop="sendCount" label="发货数量" width="110" align="center"></el-table-column>
                    <el-table-column label="物料详情" width="140" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <span>SIM卡{{scope.row.sendCount}}张，IMEI型号{{scope.row.sendCount}}个</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receivingUser" label="发货对象" width="100" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="receivingUserTel" label="收件人电话" width="100" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="receivingAddress" label="发货地址" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <!--  <el-table-column label="发货状态" width="100"  align="center">
                          <template slot-scope="scope">
                              <span v-if="scope.row.status == true" style="color: green">已发货</span>
                              <span v-if="scope.row.status != true" style="color: red">未发货</span>
                          </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="备注"  align="center" show-overflow-tooltip></el-table-column>
                      -->
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="shippingVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="information">
            <el-dialog
                    :close-on-click-modal="false"
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    width="70%"
                    style="margin-top: -110px"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left">
                                <tr>
                                    <td style="padding: 5px;width: 80px">
                                        公司名称：
                                    </td>
                                    <td style="width: 330px;">
                                        {{testForm.companyName}}
                                    </td>
                                    <td style="padding: 5px;width: 80px">协议号：</td>
                                    <td style="width: 330px">
                                        {{testForm.contractNum}}
                                    </td>
                                    <td style="padding: 5px;width: 80px">测试周期：</td>
                                    <td style="width: 330px">
                                        {{testForm.startDate}} ~ {{testForm.endDate}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 5px;width: 80px">
                                        公司类型：
                                    </td>
                                    <td style="width: 330px;">
                                        {{testForm.companyType==0?"建机厂":(testForm.companyType==1?"国企":(testForm.companyType==2?"租赁商":(testForm.companyType==3?"代理商":"集团公司")))}}
                                    </td>
                                    <td style="padding: 5px;width: 80px">塔机数量：</td>
                                    <td style="width: 330px">
                                        {{testForm.towerCount}}
                                    </td>
                                    <td style="padding: 5px;width: 150px">升降机数量：</td>
                                    <td style="width: 330px">
                                        {{testForm.lifterCount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 5px;width: 80px">项目名称：</td>
                                    <td style="width: 330px">
                                        {{testForm.projectName}}
                                    </td>
                                    <td style="padding: 5px;width: 80px">设备类型：</td>
                                    <td style="width: 330px">
                                        {{testForm.deviceType == 0?'普通型':'加强型'}}
                                    </td>
                                    <td style="padding: 5px;width: 150px">是否安装：</td>
                                    <td style="width: 330px">
                                        {{testForm.isInstall == 1?"是":"否"}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 5px;width: 80px">备注：</td>
                                    <td style="width: 330px" colspan="5">
                                        {{testForm.remark}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>功能需求：</td>
                                    <td colspan="5" style="padding: 10px 0">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        border
                                        :data="basicTable"
                                        style="width: 100%"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" align="center" label="规格型号"></el-table-column>
                                    <el-table-column prop="siteNum" align="center" label="现场编码"></el-table-column>
                                    <el-table-column prop="stringDiameter" align="center"
                                                     label="钢丝绳直径(mm)"></el-table-column>
                                    <el-table-column prop="armLength" align="center" label="前臂长度(m)"></el-table-column>
									<el-table-column prop="rearArmLength" align="center" label="后臂长度(m)"></el-table-column>
                                    <el-table-column prop="maxLoad" align="center" label="最大载重(T)"></el-table-column>
                                    <el-table-column prop="deviceType" align="center" label="类型"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </div>
                    <div class="approval-table">
                        <div class="text-title">
                            审批记录
                        </div>
                        <template>
                            <el-table
                                    :data="approvalRecord"
                                    border
                                    style="width: 100%">
                                <el-table-column prop="depName" label="部门"></el-table-column>
                                <el-table-column prop="userName" label="审批人"></el-table-column>
                                <el-table-column prop="auditRemark" label="意见">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.auditRemark == null">无</span>
                                        <span v-else>{{scope.row.auditRemark}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="时间" :formatter="dateFormat">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.createTime == null">无</span>
                                        <span v-else>{{scope.row.createTime}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.isPass == 0 && scope.row.isEffective == 1"
                                              style="color: red">驳回</span>
                                        <span v-else-if="scope.row.isPass == 0 && scope.row.isEffective == 0"
                                              style="color: #F08080">驳回</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 1"
                                              style="color: green">通过</span>
                                        <span v-else-if="scope.row.isPass == 1 && scope.row.isEffective == 0"
                                              style="color: #98FB98">通过</span>
                                        <span v-else style="color: blue">未审批</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                    <div class="file-info">
                        <div class="text-title">
                            附件信息及下载
                        </div>
                        <div class="down-file">
                            <div class="file-type">
                                <div style="width: 8%;padding: 10px">
                                    设备型号清单:
                                </div>
                                <div style="width: 87%;padding: 12px">
                                    <a :href="urlFilePath + 'erp' + testForm.deviceListUrl">
                                        {{testForm.deviceListUrl==null?'':testForm.deviceListUrl.split("/").slice(-1)+
                                        ''}}
                                    </a>
                                </div>
                            </div>
                            <div class="file-type">
                                <div style="width: 8%;padding: 10px">
                                    项目信息清单:
                                </div>
                                <div style="width: 87%;padding: 12px">
                                    <a :href="urlFilePath + 'erp' + testForm.projectMsgUrl">
                                        {{testForm.projectMsgUrl==null?'':testForm.projectMsgUrl.split("/").slice(-1)+
                                        ''}}
                                    </a>
                                </div>
                            </div>
                            <div class="file-type">
                                <div style="width: 8%;padding: 10px">
                                    其它附件:
                                </div>
                                <div style="width: 87%;padding: 12px">
                                    <a :href="urlFilePath + 'erp' + testForm.otherAttachUrl">
                                        {{testForm.otherAttachUrl==null?'':testForm.otherAttachUrl.split("/").slice(-1)+
                                        ''}}
                                    </a>
                                </div>
                            </div>
                            <ol>
                                <li v-for="(file,i) in files" class="file-list" :key="i">
                                    <div style="width: 60%">
                                        <a :href="urlFilePath + 'erp' + file.attachmentUrl">{{file.attachmentName}}</a>
                                    </div>
                                    <div style="width: 10%">
                                        <span>{{formatDate(file.createTime,'yyyy-MM-dd')}}</span>
                                    </div>
                                    <div style="width: 10%">
                                        <span>{{file.createUserName}}</span>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                    title="审批记录"
                    :visible.sync="exportDialogVisible"
                    :close-on-click-modal="false"
                    width="52.6%" center>
                <download-excel
                        class="export-excel-wrapper"
                        :data="json_data"
                        :fields="json_fields"
                        :name="fileName">
                    <el-table
                            :data="tableData"
                            max-height="400px"
                            size="small"
                            row-class-name="row"
                            cell-class-name="column"
                            :highlight-current-row="true"
                            fit>
                        <el-table-column
                                v-for="(item, index) in jsonFields"
                                :key="index"
                                :prop="item.prop"
                                :width="item.width"
                                :label="item.label" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                    <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                               @click="exportDialogVisible=false">导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import test from '../../../public/json/test.json'
    import shipping from '../../../public/json/shipping.json'
    import funOptions from '../../../public/json/funOptions.json'
    import amount from '../../../public/json/amount.json'
    import approval from '../../../public/json/approval.json'
    import basicData from '../../../public/json/basicData.json'
    import contract from "../../../public/json/contract.json";

    export default {
        data() {
            return {
                pageList:[14,20,30,50],
                tableHeight: '',
                isDisabled: false,
                specialItems: [],
                files: [],
                urlFilePath: process.env.VUE_APP_CURENV,
                contractId: '',
                uploadType: '',
                lineHeight: '',
                jsonFields: [
                    /* {label: '', width: '40', prop: 'id'},*/
                    {label: '协议编号', width: '120', prop: 'number'},
                    {label: '销售人员', width: '120', prop: 'salesman'},
                    {label: '联系电话', width: '120', prop: 'phone'},
                    {label: '测试周期(月)', width: '120', prop: 'testCycle'},
                    {label: '公司名称', width: '120', prop: 'companyName'},
                    {label: '公司类型', width: '120', prop: 'unitType'},
                    {label: '审批状态', width: '120', prop: 'approvalStatus'},
                    {label: '备注', width: '120', prop: 'remark'},
                ],
                fileName: '',
                fields: [],
                values: [],
                json_fields: {},
                json_data: [],
                towerItems: [],
                lifterItems: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                editTile: '',
                isClose: false,
                exportDialogVisible: false,
                basicTable: [],
                collectionRecord: [],
                approvalRecord: [],
                checkAll: false,
                functions: [],
                funNameList: [],
                isIndeterminate: false,
                dialogVisibles: false,
                shippingVisible: false,
                infoVisible: false,
                multipleSelection: [],
                delList: [],
                saleList: [],
                // totalPage:0,
                searchItem: {
                    contractNum: '',
                    userName: '',
                    userTel: '',
                    companyName: '',
                    companyType: '',
                    contractType: '',
                    projectName: '',
                    auditState: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1
                },
                totalPage: 30,
                dialogVisible: false,
                option: {
                    pageIndex: '',
                    pageSize: 14,
                    currentPage: 2
                },
                approvalStatuses: [
                    {
                        label: '草稿',
                        value: 0
                    },
                    {
                        label: '已通过',
                        value: 10
                    },
                    {
                        label: '已驳回',
                        value: -1
                    },
                    {
                        label: '审批中',
                        value: 1
                    },
                ],
                unitTypes: [
                    {
                        label: '建机厂',
                        value: 0
                    },
                    {
                        label: '国企',
                        value: 1
                    },
                    {
                        label: '租赁商',
                        value: 2
                    },
                    {
                        label: '代理商',
                        value: 3
                    },
                    {
                        label: '集团公司',
                        value: 4
                    },
                ],
                UpDialogVisible: false,
                tableData: [],
                shippingData: [],
                testForm: {
                    contractName: '',
                    contractNum: '',
                    companyName: '',
                    companyType: '',
                    towerCount: '',
                    lifterCount: '',
                    deviceListUrl: '',
                    projectMsgUrl: '',
                    otherAttachUrl: '',
                    projectName: '',
                    endDate: '',
                    startDate: '',
                    isInstall: '',
                    contractType: '',
                    chargePolicy: '',
                    specialPolicy: '',
                    remark: '',
                    deviceType: '',
                    id: ''
                },
                showTitle: '',
                rules: {
                    contractName: [{required: true, message: '协议名称不能为空', trigger: 'blur'}],
                    number: [{required: true, message: '协议号不能为空', trigger: 'blur'}],
                    companyName: [{required: true, message: '公司名称不能为空', trigger: 'blur'}],
                    chargingPolicy: [{required: true, message: '收费政策不能为空', trigger: 'blur'}],
                    specialPolicy: [{required: true, message: '特殊政策及发货说明不能为空', trigger: 'blur'}],
                    startDate: [{required: true, message: '开始时间不能为空', trigger: 'blur'}],
                    endDate: [{required: true, message: '截止时间不能为空', trigger: 'blur'}],
                    quality: [{required: true, message: '数量不能为空', trigger: 'blur'}]
                }
            }
        },
        methods: {
            submitTest(id) {
                this.$confirm('你确定要提交此试用协议吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.passProtocol({id: id, isPass: 1}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh")
                            this.$message.success(res.message);
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }).catch(() => {
                })
            },
            saveTest(formName) {
                this.isDisabled = true
                this.$refs[formName].validate(item => {
                    if (item) {
                        this.$api.updateProtocol(this.testForm).then(res => {
                            if (res.code == 200) {
                                this.pageChange("refresh");
                                this.dialogVisible = false;
                                this.$message.success(res.message);
                                this.isDisabled = false
                            } else {
                                this.isDisabled = false
                                this.$message.error(res.message);
                            }
                        })
                    } else {
                        this.$message.error("请检查数据是否填写完整");
                        this.isDisabled = false
                    }
                })
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            shippingShow(id) {
                this.shippingVisible = true;
                this.$api.getContractShipping({id: id}).then(res => {
                    if (res.code == 200) {
                        this.shippingData = res.data
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pageProtocol(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    } else {
                        this.$message.error("连接服务器失败，请联系管理员")
                    }
                })
            },
            pageSizeChange(val){
                this.searchItem.pageSize = val;
                this.$api.pageProtocol(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            chooseSelection(val) {
                this.multipleSelection = val;
            },
            editTest(id) {
                this.towerItems = [];
                this.lifterItems = [];
                this.dialogVisible = true;
                this.$api.getTest({id}).then(res => {
                    if (res.code == 200) {
                        this.testForm = {
                            contractName: res.data.contractName,
                            contractNum: res.data.contractNum,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            towerCount: res.data.towerCount,
                            lifterCount: res.data.lifterCount,
                            isInstall: res.data.isInstalled,
                            deviceListUrl: res.data.deviceListUrl,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            contractType: res.data.contractType,
                            chargePolicy: res.data.chargePolicy,
                            projectName: res.data.projectName,
                            endDate: res.data.endDate,
                            startDate: res.data.startDate,
                            specialPolicy: res.data.specialPolicy,
                            remark: res.data.remark,
                            deviceType: res.data.deviceType,
                            id: res.data.id
                        }
                        this.editTile = '编辑《' + res.data.contractNum + '》信息';
                        this.basicTable = res.data.deviceList;
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        this.approvalRecord = res.data.auditList;
                    }
                })
            },
            deleteTest(id) {

            },
            exportExcel() {
                this.exportDialogVisible = true;
                this.fileName = "测试协议";
            },
            searchBtn() {
                this.pageChange(1)
            },
            uploadFiles(id) {
                this.dialogVisibles = true
                this.contractId = id
                this.uploadType = 'contract'
            },
            openDialog(val) {
                this.dialogVisibles = !this.dialogVisibles
            },
            showTest(id) {
                this.towerItems = [];
                this.lifterItems = [];
                this.infoVisible = true;
                this.$api.getTest({id}).then(res => {
                    if (res.code == 200) {
                        this.testForm = {
                            contractName: res.data.contractName,
                            contractNum: res.data.contractNum,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            towerCount: res.data.towerCount,
                            projectName: res.data.projectName,
                            lifterCount: res.data.lifterCount,
                            isInstall: res.data.isInstalled,
                            startDate: res.data.startDate,
                            deviceListUrl: res.data.deviceListUrl,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            endDate: res.data.endDate,
                            contractType: res.data.contractType,
                            chargePolicy: res.data.chargePolicy,
                            specialPolicy: res.data.specialPolicy,
                            remark: res.data.remark,
                            deviceType: res.data.deviceType,
                            id: res.data.id
                        }
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        this.basicTable = res.data.deviceList;
                        this.approvalRecord = res.data.auditList;
                        this.files = res.data.attachmentList;
                        this.showTitle = '《' + res.data.contractNum + '》信息详情'
                    }
                })
            },
            handleCheckAllChange(val) {
                this.testForm.demand = val ? this.functions : [];
                this.isIndeterminate = false;
            },
            toChangeFormal(id) {
                this.$confirm('你确定要将此协议转为正式合同吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.convertContract({id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            handleCheckedFunctionsChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.functions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.functions.length;
            },
            resetBtn() {
                this.searchItem = {
                    contractNum: '',
                    userName: '',
                    userTel: '',
                    companyName: '',
                    companyType: '',
                    contractType: '',
                    auditState: '',
                    startDate: '',
                    projectName: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1
                }
                this.searchBtn();
            }
        },
        created() {
            this.searchBtn();
            this.$api.getSaleList().then(res => {
                if (res.code == 200){
                    this.saleList = res.data
                }
            })
            this.jsonFields.map((item, i) => {
                this.json_fields[item.label] = item.prop
            })
            this.json_data = test.data;
            this.collectionRecord = amount.data;
            this.basicTable = basicData.data;
            this.shippingData = shipping.data;
            this.functions = funOptions.data;
            this.tableHeight = (window.innerHeight)*0.67
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title {

    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 20px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-right: 20px;
    }

    .icontongguo {
        color: green;
        margin-left: 20px;
    }



    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 130px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-input {
        display: inline-block;
    }

    .handle-input-date {
        width: 150px;
        display: inline-block;
        padding-right: 0px;
    }

    .information {
        background-color: #F0F2F5;
    }

    .dialog-title {
        margin: 0 20px;
        color: #67ADD9;
        font-size: 20px;

        /*border: 1px red solid;*/
    }

    .payment-table, .approval-table, .file-info {
        margin-top: 50px;
    }

    .basic-info .el-button {
        background: linear-gradient(to bottom, #39AACE, #E1E3E3);
    }

    .payment-table .el-button {
        border: 1px green solid;
        background: linear-gradient(to bottom, #39AACE, #E1E3E3);
    }

    .approval-table .el-button {
        border: 1px black solid;
        background: linear-gradient(to bottom, #39AACE, #E1E3E3);
    }

    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1100px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .upload-files {
        margin-top: 50px;
    }

    .file-type {
        margin-top: 10px;
        width: 100%;
        display: flex;
    }

    .file-list {
        width: 100%;
        display: flex;
        margin-left: 10px;
    }

    .td-text {
        width: 90px;
        text-align: right;
    }

    .td-input {
        width: 28%;
    }
</style>
